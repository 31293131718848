import React from 'react';
import {Button,CssBaseline,TextField,Typography
        ,Container,CircularProgress  ,Box,CardMedia} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { useHistory } from "react-router-dom";
import axios from 'axios';
//import jwt from 'jsonwebtoken';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const Login = () => {
    let history = useHistory();
    const [aviso, setAviso] = React.useState(false);
    const [mensaje, setMensaje] = React.useState('');
    const {guardarUsuario}=React.useContext(AuthContext);
    const {guardarGenerales}=React.useContext(GeneralesContext);  
    const [espera,setEspera]=React.useState(false);
    const [usuario,setUsuario]=React.useState(  { nombre:'',contrasenia:'' } )
    const {nombre,contrasenia}=usuario    
    const classes = useStyles();
    React.useEffect(()=>{ 
        const veriSesion = async () => {
            const usu= localStorage.getItem('UsuarioNom');            
            const nvl= localStorage.getItem('Tipo')? parseInt(localStorage.getItem('Tipo')):0;   
            const fecha = localStorage.getItem('fch22');
            let bandera=false
            if (fecha)
            {   let fechaMod=moment(fecha).add(24,'h').format("YYYY-MM-DDTHH:mm")
                let hoy=moment( ).format("YYYY-MM-DDTHH:mm")
                if (fechaMod>hoy)
                {   bandera=true
                }
            }    
            if( usu !== null && bandera)
            {
                guardarUsuario( { Nombre : usu, nivel : nvl } );   
               // guardarGenerales( { Titulo : 'Listado', Terminal : auxTerminal } )     
                if ( nvl===2) 
                {   history.push("/lista-promovido")		  
                } 
                else 
                {    if (nvl>=7&&nvl!==11 ) 
                    {   history.push("/organizacion")		  
                    }
                    else
                    {   history.push("/inicio")
                    }      
                }              
            }
            else
            {   history.push("/")
                guardarUsuario([]);                                   
                guardarGenerales([])   
                //document.title = 'NuevoEspacio Login'  
            }
        }
        veriSesion();				
    },[]);

 
   
    async function autenticar(){           
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`autenticar-web-adm`; 
        let config = {
        url:auxU ,
        method: 'POST',
        headers: { 'content-type' : 'application/json'},
        data: {
            user:nombre,
            pass:contrasenia,
            idDispositivo:"987ygubhjnjik"                                      
        }
        };
        let aux=[];
        await axios(config)
        .then(res => {        
            aux=res.data
            if (aux) 
            {   let auxCode=uuidv4()       
                let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
                localStorage.setItem('UsuarioNom',aux.userNombre);
                localStorage.setItem('UsuId',aux.userId);       
                localStorage.setItem('UsuDto',aux.userDto);
                localStorage.setItem('UsuMun',aux.userMun);
                localStorage.setItem('UsuSec',aux.userSec);           
                localStorage.setItem('Tipo',aux.userTipo);
                localStorage.setItem('token20',aux.userToken);         
                localStorage.setItem('Operacion',aux.userOperacion);  
                localStorage.setItem('Dispvo',auxRecorte);  
                localStorage.setItem('fch22',moment().format('YYYY-MM-DDTHH:mm'));
                localStorage.setItem('usuTitu',aux.userTitutlar)
                //guardarGenerales({Titulo:'Listado',Terminal:aux.userTerminal})   
                guardarUsuario({Nombre:aux.userNombre,nivel:aux.userTipo});  
                if (aux.userTipo===2) 
                {
                    history.push("/lista-promovido")		  
                } 
                else {
                    if (aux.userTipo>=7 && aux.userTipo!==11) 
                    {                            
                        history.push("/estafeta")		  
                    }
                    else
                    {
                        history.push("/inicio")
                    }		      
                }
                
            }
            else
            {
                setAviso(true);
                setMensaje("Usuario o contraseña incorrecta")
            }                           
        }).catch(auxerror => {                
            if(auxerror && auxerror.response && auxerror.response.status === 500)
            {
                console.log(auxerror.response.data.mensaje);          
            }
        });
        setEspera(false);
    }  
     

    const handleCloseSN = () => {
        setMensaje('');
        setAviso(false);
    };
    
    const onSubmit = e =>
    {   e.preventDefault();    
        if (nombre.length>=4&&contrasenia.length>=4)
        {   autenticar();     
            const usu= localStorage.getItem('UsuarioNom');

            if(usu)
            {   console.log("volviendo a cambiar");
                history.push("/inicio")		  
            }        
        }
        else
        {
            setAviso(true);
            setMensaje("Debe llenar  Usuario o Contraseña correctamente")
        }                                        
	}
    
    const onChange = e =>
	{  
        let expNopermitida = new RegExp('[%{}*|`]');        
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9.$@#_&-+()/:;!?,<>=]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setUsuario({
                ...usuario, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};
   
    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box>
                    <CardMedia style={{marginBottom:1+"rem",width:"15rem"}}
                        component="img" alt="Logo"
                        //height="200" 
                        image="/Nespacio_LOGO.svg" title="Logo"
                    />
                </Box> 
                <Typography component="h1" variant="h5">
                    Iniciar Sesión
                </Typography>
                { aviso && mensaje.length !== 0 ? 
                    <Alert severity="warning" onClose={handleCloseSN}> { mensaje } </Alert>
                    :null
                }
                <form className={classes.form} onSubmit={onSubmit} noValidate >
                    <TextField
                         margin="normal" required fullWidth id="nombre-login01"
                        label="Usuario" name="nombre" type="text" value={nombre}              
                        onChange={onChange} autoFocus //variant="outlined"
                    />
                    <TextField
                        margin="normal" required fullWidth
                        name="contrasenia" label="Contraseña" type="password" id="contrasenia-login01"
                        value={contrasenia} onChange={onChange}         //  variant="outlined" 
                    />           
                    <Box align="center">
                        {!espera ?
                            <Button type="submit" fullWidth
                                variant="contained" color="primary"  className={classes.submit}
                            >
                                Iniciar Sesión
                            </Button> 
                            : <Box mt={2}> <CircularProgress /> </Box>
                        }
                    </Box>                       
                </form>
            </div>        
        </Container>
    );
}

export default Login
