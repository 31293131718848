import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
 
import { TextField,FormControl,  
    Select,MenuItem,InputLabel,InputAdornment,IconButton,Input,
    FormLabel,FormControlLabel,RadioGroup,Radio} from '@material-ui/core' 
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrar';
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast } from 'react-toastify'; 
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
  
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const NuevoUsuario = ({modalAbierto,setModalAbierto ,titulo,listMuni,listTipoUsu,recargar, listTitular,listTitularEsp }) => {
    let history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();  
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const[nvoRegis,setNvoRegis]=useState({Nombre:'',
                                               
                                                Email:'' ,Pass:'',Tel:'',TipoUsu:2,usuNick:'', muni:"", Titular:"1",Superior:""
                                            });  
    const[visiblePwd,setVisiblePwd]= useState(false)                                                                                          
 
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] =  useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const{  Nombre,  Email, Pass, Tel, TipoUsu, usuNick, muni, Titular,Superior}=nvoRegis
    const [listAuxTitu, setListAuxTitu] = useState([]);
    useEffect(() => {      
        if (TipoUsu===5) 
        {
            setListAuxTitu( listTitular)
        }
        if (TipoUsu===11) {
            setListAuxTitu( listTitularEsp)
        }   
    }, [TipoUsu])
    

    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis,  
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};         

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		    

	}; 
 
    const onChangeSelect = e =>
	{   e.preventDefault();
        setNvoRegis({
            ...nvoRegis, 
            [e.target.name] : e.target.value  
        })                       
	};      
 
    function llamadaNvoRegis( )  {             
        const url=`usuario-nuevo-multiple-1-1`;                                                                        
        const data = qs.stringify({                                                                   
            tipo:TipoUsu,//parseInt(TipoUsu),
            nombre:Nombre,
            usuario:usuNick,
            pass:Pass,
            email:Email,
            telefono:Tel,  
            Estatus:1,    
            Municipio:muni!==0 ? muni:muni,       
            Titular:TipoUsu!==5 && TipoUsu!==11 ?1:Titular,
            Superior:(TipoUsu!==5 && TipoUsu!==11) || Titular!=="0" ?0:Superior.Id,
            idDispositivo:'451224452121'
        });
 
        function respuesta(aux) {                                                                     
          if (aux.length!==0) 
          {                            
            recargar()
            setBtnDeshabilitado(true)                                             
            const mensaje="Se registro correctamente el Nuevo Usuario"
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            toast.success(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `nvo-usuario${1}`
            })
            setModalAbierto(false)
          }                                            
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);      
    };

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoRegis({
                    ...nvoRegis,
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const verificaNulosSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if(campo === '' || campo === null ||campo == 0 )
        {   respuesta=true;                             
            setMensaje('Debe seleccionar campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }//console.log(campo+descripcion);  
        return(respuesta);
    }

    const verificaNombre=(nombre,appat,apmat)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (!verificaNulos(nombre,'Nombre') && !verificaNulos(appat,'Apellido Paterno') && !verificaNulos(apmat,'Apellido Materno')  ) 
        {        
            if( expCadena.test(nombre) && !expNumero.test(nombre) )
            {
                if(  expCadena.test(appat) && !expNumero.test(appat) )
                {
                    if ( expCadena.test(apmat) && !expNumero.test(apmat)  ) 
                    {  
                        respuesta=true;                     
                    }else
                    {   setMensaje('Debe escribir un Apellido Materno  valido ');
                        setTipoAdvertencia("warning")  
                        setAviso(true);      
                    }
                }
                else
                {   setMensaje('Debe escribir un Apellido Paterno  valido ');
                    setTipoAdvertencia("warning")  
                    setAviso(true);
                }
            }
            else
            {   setMensaje('Debe escribir un Nombre  valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);
            }    
        }    
        return(respuesta);
    }
    
    const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true;      
            setMensaje('Campo Email vacio, debe llenar el campo ' );
            setTipoAdvertencia("warning")  
            setAviso(true);                     
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   setMensaje('Debe escribir un Correo valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);                           
            }            
        }
        return(respuesta);
    }  
  
    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }
 
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }

    function verificaUsu() 
    {   let respuesta=false
        if ( !verificaNulos(Email,'Email') ) 
        {   if (Email.length>=8 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Email incompleto, minimo 8 caracteres , debe escribir un Email valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    function guardarDatos(e)
    {   e.preventDefault()        
 
        if (!verificaNulos(TipoUsu,'Tipo') 
            && ( ( (TipoUsu === 5 || TipoUsu === 11) && !verificaNulos(muni,'Municipio')  ) || (TipoUsu !==5 && TipoUsu !== 11))
            && ( ( (TipoUsu === 5|| TipoUsu === 11) && (Titular==="1" || ( Titular==="0" && !verificaNulos(Superior,'Superior') ) ) )
                || (TipoUsu !==5 && TipoUsu !== 11) )    
            && validaVariable(Nombre,'Nombre',7) &&validaVariable(usuNick,'Usuario',4)   
            && valContra() && validaVariable(Tel,'Telefono',10))
        {           
            setMensaje('');                
            setAviso(false);                 
            llamadaNvoRegis()        
        }                                  
    } 
      
    const guardarMun=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoRegis({
                ...nvoRegis,
                muni : e.target.value 
            })      
        }else
        { setNvoRegis({
            ...nvoRegis,
            muni : ""  
        })           
        }        
    }

    const guardarSupr=(e)=>{    
        
        if (e!== null && e!== undefined) 
        {                            
            setNvoRegis({
                ...nvoRegis,
                Superior : e  ,                 
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setNvoRegis({
                ...nvoRegis,
                Superior : ""   
            })           
        
        }        
    }

    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoRegis({
                ...nvoRegis,
                TipoUsu :e.target.value ,
                Superior : "" 
            })      
        }else
        { setNvoRegis({
            ...nvoRegis,
            TipoUsu : ""  ,
            Superior : "" 
        })           
        }        
    }

    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
      };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();
        //setVisiblePwd(!visiblePwd)
    };

    const onChangeRad=(e)=>{
        setNvoRegis({...nvoRegis,[e.target.name] : e.target.value,  Superior : ""  })            
    }

    const DatosGenerales=(
        <div>
   
          {listTipoUsu.length!==0 ?                
               <FormControl  style={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} >
                   <InputLabel id="tipo">Tipo</InputLabel>
                   <Select  
                     label="Tipo" id="TipoUsu" name="TipoUsu"
                     value={TipoUsu} onChange={guardarTipoSelect}
                   >
                    {listTipoUsu.map((secc, index) => {
                        if(secc.Id>0) {
                        return(
                        <MenuItem value={secc.Id} key={secc.Id}>
                            <em>{secc.Nom }</em>
                        </MenuItem>
                        )}
                    } )}
                    

                   </Select>
                </FormControl>
            :   null
            }
            {nvl===1 && (TipoUsu===5 || TipoUsu===11)&& listMuni && listMuni.length !== 0   ? 
                <div>
                <FormControl  style={{  minWidth:20+'rem' }} >
                    <InputLabel id="inpt-mun">Municipio</InputLabel>
                    <Select  
                    label="Municipio" id="sel-muni" name="muni"
                    value={muni} onChange={guardarMun}
                    >
                    {listMuni.map((mun  ) => {
                        if(mun.Id>0) {
                        return(
                        <MenuItem value={mun.Id} key={mun.Id}>
                            <em>{mun.Nom }</em>
                        </MenuItem>
                        )}
                    } )}
                    

                    </Select>
                </FormControl>
                </div>
            :null}
             {nvl===1 && (TipoUsu===5 || TipoUsu===11)    ? 
                <div>        
                <FormControl component="fieldset" style={{marginLeft:.5+'rem',marginTop:".5rem" }}>          
                   {/*  <FormLabel  >Tipo</FormLabel>      */}   
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="Titular" value={Titular} onChange={onChangeRad}
                    >   
                        <FormControlLabel value={"1"}  label={"Titular"}     
                            control={<Radio color="primary" />} />                             
                        <FormControlLabel value={"0"}  label={"Encargado"}             
                            control={<Radio color="primary" />} />
                                                                                      
                    </RadioGroup>                     
                </FormControl>  
                </div>
            :null}
            {nvl===1 && (TipoUsu===5 || TipoUsu===11)&& listTitular && listAuxTitu.length !== 0  && Titular==="0" ? 
                <div> 
                <Autocomplete
                    id="sel-Titular" size='small' style={{ width: 20.7+'rem',marginTop:".5rem" }}
                    onChange={(e,option)=>guardarSupr(option)}  value={Superior}      
                    getOptionLabel={(option) => option.UsrNom} name="Superior"             
                    noOptionsText={"Sin coincidencias"} options={listAuxTitu}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Superior" variant="outlined" />
                    )} 
                />
                </div>
            :null}
           <br/>
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:23+'rem' }}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off' // defaultValue="Nombre"                
            />
            <br/>
            <TextField  id="Usu-input2" onChange={onChangeCorreo} style={{width:11.5+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                autoComplete='off'
            />      
           {/*  <TextField
                required name="Pass" label="Contraseña" type="password" id="Pass"
                value={Pass} onChange={onChange} size="small" style={{width:11.5+'rem'}}     
                autoComplete='off' 
            />     */}
            <FormControl  size="small" style={{width:11.5+'rem'}} >
                <InputLabel htmlFor="pwd-usu1-txt2" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-pwd-usu2"
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='off' name="Pass"
                    onChange={onChange } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:15.5+'rem'}}
                label="Email"  value={Email} name='Email' size="small"//defaultValue="Usuario"
                autoComplete='off'
            /> 
            
        </div>
 
    )       
 
    return (
        <>           
         <Modal
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}        
        >
            {DatosGenerales}
        </Modal> 
            
        </>
    )
}

export default NuevoUsuario
