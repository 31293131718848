import React,{useState,useContext} from 'react';
import {Breadcrumbs,Typography,Link, Box,  LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import axios from 'axios';
import qs from 'qs';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
//import NombreIni from './General'
//import MunicipioInicio from './MuniInicio';
import RegionalInicio from '../regional/RegionalInicio'
import MunicipioInicio from '../municipio/MunicipioInicio';
import ZonaInicio from '../Zona/ZonaInicio'
import { llamaApiCargaToken, ErrorGeneral } from '../funciones/ClienteAxios';
import Especial from '../municipioEspecial';
const useStyles = makeStyles({
    textoSelect: {
      '&:hover': {
        color:'#9ccdfa',
      },
      fontSize:1.25+'rem'
    },
});

const Principal = () => {
    const history = useHistory();     
    const classes = useStyles();    
    const usuID = localStorage.getItem('UsuId');
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo')):0;    
    const source = axios.CancelToken.source();    
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext); 
    const[listMuni,setListMuni]= useState([]);
    const[opcion,setOpcion]= useState(2)
    const [espera,setEspera]= useState(false);
    
    React.useEffect(()=>{
        const usu = localStorage.getItem('UsuarioNom');
                      
        function llamadaListMuni()  {            
            const auxU= `lugar-municipio`;                            
            const dataInfo = qs.stringify({                
                'usuario':usuID,              
                'estado':process.env.REACT_APP_EDO,                
                'idDispositivo':'w8rf51v21dsd2cs',
                });
                     
            function respuesta (aux) 
            {   if (aux[0].Id != -1) 
                {                                                    
                    setListMuni(aux)
                }                                                            
            }
            llamaApiCargaToken(dataInfo, auxU, respuesta, ErrorGeneral, setEspera, history, source);
        };
      
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion ||(usu===undefined||usu===''||usu===null)||(nvl===undefined||nvl===''||nvl===null)
                ||(usuID===undefined||usuID===''||usuID===null) )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                                             
                }                        
                llamadaListMuni();                                 
                guardarGenerales({...generales,Titulo:'Inicio'})         
                if (nvl===11) {
                    setOpcion(2)   
                }                                             
            }   
        }

        veriSesion()

    },[])
    
    const selectOpcion =(e,auxValor)=>{
        e.preventDefault();
        //console.log(auxValor);
        setOpcion(auxValor)
    }
    
    const menu=(<Breadcrumbs separator="-" aria-label="breadcrumb" >                
                {/* 
                */}
                {/* nvl!==11?
                 <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 3 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,3)}
                >
                    Estatal
                </Typography> 
                 :null */}
                {/* nvl!==11?
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 1 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,1)}
                >
                    Zonas
                </Typography>
               :null */}
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 2 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,2)}
                >
                {nvl === 11 ? "Estatal":"Municipios"}
                </Typography>         
                {nvl!==11?
                <Typography variant="h5" className={classes.textoSelect} 
                    color={opcion === 4 ? "Primary":'textPrimary'} onClick={e=>selectOpcion(e,4)}
                >
                    Especial
                </Typography>                
                :null}
            </Breadcrumbs>
        )
    
    const contenido4 = opcion === 4 ? <Especial listMuni={listMuni} /> : null    
    const contenido3 = opcion === 3 ? <ZonaInicio listMuni={listMuni} /> : contenido4
    const contenido2 = opcion === 2 ? <MunicipioInicio listMuni={listMuni} /> : contenido3                        
    const contenido = opcion === 1 ? <RegionalInicio /> : contenido2        
    const auxContenido=espera?     (<Box pt={3}>
        <LinearProgress/> 
        </Box>)  :contenido
   
  return( 
  <div>
    <Box display="flex" pt={1}  style={{justifyContent:'center'}}>
      {menu}
    </Box>
      {auxContenido}
  </div>);
};

export default Principal;