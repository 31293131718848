import React,{ useState } from 'react'
import {useHistory} from "react-router-dom";
import ModalInformativo from '../generales/ModalInformativo'
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import { Box, IconButton, TextField, Typography } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
const BusqSeccion = ({modalAbierto,setModalAbierto,}) => {
    const history = useHistory();
    const source = axios.CancelToken.source();
    const[aviso,setAviso]=useState(false)
    const[espera,setEspera]=useState(false)
    const[textoSecc,setTextoSecc]=useState("")
    const[respuesta,setRespuesta]=useState("")

    const llenalistMun = (auxSecc) => {
        const data = qs.stringify({        
            Seccion: auxSecc,
            idDispositivo: "987gyubjhkkjh",
        });
        const url = "lugar-seccion-muni";
    
        function respuesta(aux) {
          if (aux && aux.MuniNom ) 
          {
            setRespuesta(`${aux.MuniNom} ${aux.MuniNum}` )
          }       
        }
    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
      }; 

    const onChange = e => {
        setTextoSecc( e.target.value)
    }

    const Busqueda=()=>{
        if (textoSecc!=="") {
            llenalistMun(textoSecc)
        }
    }
  return (
    <ModalInformativo   titulo="Busqueda de Sección"
    modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto}
    tamanio="sm"
 >
        <Box display={"flex"} style={{alignItems:"center"}}>
        <Box  width={100}>
          <TextField id="textoSecc-txt" name="textoSecc" label="Sección" fullWidth size="small"
            value={textoSecc} onChange={onChange} variant="outlined"/>  
          
        </Box>
        <IconButton onClick={Busqueda} style={{marginLeft:"1rem"}}>
                <ReplayIcon/>
        </IconButton>
      { respuesta!==""? <Box ml={1}  width={400} >
            <Typography>
                {`${respuesta}`}
            </Typography>
        </Box>:null}
        </Box>
    </ModalInformativo>
  )
}

export default BusqSeccion