import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { Box,IconButton, LinearProgress, Paper,Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip, TablePagination, CircularProgress} from '@material-ui/core';
import qs from 'qs';
import {ErrorGeneral, llamaApiCargaToken} from '../funciones/ClienteAxios';
import {toast} from "react-toastify";
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor:theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

const listCache=[
{Id:2,nom: "Promovido",link:"perfil-clear-redis"},
{Id:3,nom: "Militante",link:"prospecto-redis-clear"},
{Id:4,nom: "Recorrido",link:"recorrido-redis-clear"},
{Id:5,nom: "Usuario",link:"usuario-redis-clear"},
{Id:6,nom: "Partido",link:"partido-clear-redis"},
{Id:7,nom: "Coordinador",link:"coordinador-clear-redis"},
{Id:1,nom: "Mapa",link:"lugar-redis-clear"},
{Id:8,nom: "Plantilla",link:"plantilla-clear-redis"},
{Id:9,nom: "Apoyo",link:"apoyo-clear-redis"},
{Id:10,nom: "Proyecto",link:"proyecto-redis-clear"},
{Id:11,nom: "Votacion",link:"votacion-redis-clear"},
{Id:12,nom: "Incidencia",link:"incidencia-redis-clear"},
{Id:13,nom: "Movilización",link:"movilizacion-redis-clear"},
{Id:14,nom: "Estructura",link:"estructura-redis-clear"},
{Id:15,nom: "Comite",link:"comite-redis-clear"},
{Id:16,nom: "Organizacion",link:"organizacion-redis-clear"},
]

export default function TablaCache({ history, source,idusu, setEspera}) {
    const classes = useStyles();        
    const [datos, guardarDatos] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)    
    const [selectedID, setSelectedID] = useState(null)    

    const llenarLimpiar = (auxLink) => {
        const data = qs.stringify({
           
            idDispositivo: '45vf2d1vdf21d2f'
        });
        const url = auxLink.link//"prospecto-status";

        function response(data) {
            
            if (data?.respuesta !== -1) 
            {   
                toast.success(`Se limpio la cache de ${auxLink.nom} `, {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-prospecto${1}`
                })              
            }
        }

        llamaApiCargaToken(data, url, response, ErrorGeneral,setEspera, history, source);
    };


    const seleccionar = (auxId) => {
        setSelectedID(auxId)
        llenarLimpiar(auxId)          
    }

    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
            {contenido}
        </TableCell>
        )
    }  

    function Row(props) {
        const {row} = props;
        return (           
        <TableRow onClick={() => { seleccionar( row) }}
            className={classes.tableRow} selected={selectedID?.Id === row.Id}
            classes={{hover: classes.hover, selected: classes.selected}}
        >                                                                         
            {celda("left",{ }, row.nom)}                    
        </TableRow>                      
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                                                     
                    <TableCell align="center" padding='none'>Descripcion</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {listCache.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}            
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>              
                        <TableCell align="center" padding='none'>Descripcion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRow}>               
                        <TableCell className={classes.tableCell} align="left">Prospectos</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        )
    }

    const contenido = listCache.length != 0 ? tablaCompleta(listCache) : tablaSinDatos()
   // const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
    <Paper>  
        <Box>
            {contenido}  
        </Box>             
    </Paper>
    );
}