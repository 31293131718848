import React, { useState,useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {  IconButton, Table, TableBody, TableCell, 
        TableContainer, TableHead, TableRow,Checkbox ,Box  } from '@material-ui/core';
import {useHistory} from "react-router-dom";
import { celda } from '../generales/TablaGeneral';
import CheckPermisos from './CheckPermisos';
import {CheckCircle,CheckCircleOutline} from '@material-ui/icons/';
import {toast} from "react-toastify";
import qs from 'qs';
import axios from 'axios';
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        width: '20rem',
     
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_COLOR
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});

export default function TablaPermisoMun({  datos, usrRegistro,llamaApi, }) {    
    let nvlSNPermiso = '5'   
    let history = useHistory();
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [checked, setChecked] = useState(true);
    const [todo, setTodo] = useState(false);
    const [espera,setEspera]=useState(false);
    const [listTodo,setlistTodo]=useState([]);
    const source = axios.CancelToken.source();

    useEffect(() => {
        let bandera=true
        const filtrado=datos.filter((aux)=>aux.permisoStatus === 0)
        if (filtrado.length!==0|| datos[0].Id<1) 
        {   bandera=false
        }
        
        setlistTodo([])
        setTodo(bandera)
        return () => {
            source.cancel();
        }
    }, [datos])   

    const seleccionado = (auxId, auxOrden) => 
    {   setSelectedID(auxId)       
    }

    const handleChange = (event) => 
    {   setChecked(event.target.checked);
    };    

    const cambioTodo=(e)=>{
        e.preventDefault()
        setTodo(!todo)
        let arr=[]
        datos.forEach((element)=>{
            arr.push({id:element.PerfId,valor:!todo?1:0})
        })
        setlistTodo(arr)        
    }
    
    function tablaCompleta(auxlista) {//usuario
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>                 
                    <TableCell align="center" padding='none'> </TableCell>    
                    <TableCell align="center" padding='none'>Municipios a Utilizar</TableCell>    
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length !== 0 ? 
                    (auxlista.map((row) => (
                        <CheckPermisos key={row.Id} registro={row}  usrRegistro={usrRegistro} 
                            llamaApi={llamaApi} //listaOriginal={listaOriginalPerm}
                        />                    
                    ))) 
                : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
        <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>            
                    <TableCell align="left">Municipios a Utilizar</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRow}>
                    {celda("left", {}, "Sin Perfiles", 1, classes.tableCell)}                 
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }    
    const tabla=true?tablaCompleta(datos) : null 
    const contenido = datos.length !== 0 ? tabla : tablaSinDatos()

    return (
    <span>
        {contenido}
    </span>
    );
}