import React,{useState,useEffect} from 'react'
import {Box, TextField,FormControl, Tooltip,LinearProgress,
        Select,MenuItem,InputLabel, Card,Typography,FormLabel,
        RadioGroup,Radio,FormControlLabel, Button} from '@material-ui/core' 

import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrarActualiza';
 
import {llamaApiCargaToken,ErrorGeneral, } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import CampoText from './CampoText'


const ModalNvoEdit = ({modalAbierto,setModalAbierto ,titulo,auxReg,getDatos}) => {
    let history = useHistory();    
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV = Dispvo && Dispvo.length >= 12 ? Dispvo:'451224452121'   
    
    const usuId =localStorage.getItem('UsuId') ;                
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);    
    const[registro, setRegistro] = useState({Nombre:'',Municipio:"",})  
    const[listRegistro,setListRegistro]= useState([])
    const[contador,setContador]= useState(0)
    const{Nombre,Municipio,}=registro
    const source = axios.CancelToken.source();  


    useEffect(() => {
              
        if (auxReg) 
        {              
            setRegistro({...registro,Llave:auxReg.Id,  })                      
        }

        return ()=> {
            source.cancel();
        }     
    }, [])
      
    const onChange = e =>
	{   
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({
                ...registro, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};             
 
    const llamadaNvoPerfil = () => {                    
        const data = qs.stringify({
            Usuario:usuId,
            idDispositivo: auxDispV,
        });
        const url = "estructura-nuevo";
        function respuesta(aux) {
            if (aux.length!==0)
            {   setBtnDeshabilitado(true)                                             
                const mensaje=auxReg ?"Se edito correctamente":"Se registro correctamente"
                setTipoAdvertencia("success")      
                setMensaje(mensaje);                
                setAviso(false);
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `nvo-afil${1}`
                })          
                getDatos()      
                setModalAbierto(false);
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source);
    };

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;                  
        if( campo===null || (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                                   
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }     

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
      
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
 
    function guardarDatos(e)
    {   e.preventDefault()                                
        if (!verificaNulos(Municipio,"Municipio") && validaVariable(Nombre,'Nombre',3)                                
          )
        {           
            setMensaje('');                
            setAviso(false);                            

            llamadaNvoPerfil()                               
        }                                  
    } 
    const agregarReg=()=> {
        let objeto=listRegistro
        objeto.push({texto:Nombre, tipo:1, valor:"",orden:contador})
        setListRegistro(objeto)
        console.log(objeto);
        setContador(contador+1)
    }
    const DatosGenerales=(
        <div>            
               
            <TextField required id="Nombre-input" onChange={onChange} 
                style={{width:10+'rem',marginRight:".5rem"}}
                label="Nombre" value={Nombre} name='Nombre'size="small" autoComplete='off'                     
            />   
            <Button onClick={agregarReg}>Agregar</Button>
        </div> 
    )     

    const cerrarBorrar = () => {            
        setAviso(false);
        setModalAbierto(false);
    };

    return (    
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}     
        cerrarBorrar={cerrarBorrar}   
    >
        {DatosGenerales}
        {listRegistro.length!==0?
        listRegistro.map((regis)=>{
           return <CampoText texto={regis.texto} tipo={regis.tipo} valor={regis.valor} setValor={setListRegistro} orden={regis.orden} />
        })
        :null}
    </Modal>             
    )
}

export default ModalNvoEdit