import React,{useEffect,useContext,useState} from 'react';
import {AppBar,Tooltip} from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, IconButton, Button, Box,Card } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'; 
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { ErrorGeneral,llamadaApiToken } from '../funciones/ClienteAxios';
import NumberFormat from 'react-number-format';
import MenuAndroid from '../AppAndroid';
import BusqSeccion from '../BusqSecc';
import SearchIcon from '@material-ui/icons/Search';
const drawerWidth = 240;

const useStyles =makeStyles(theme=>({    
    menuButton: {
        marginRight: theme.spacing(2),
        /*[theme.breakpoints.up('sm')]: {
            display: 'none',
          },*/
      },
    mititulo:{        
        textAlign:'center',
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            //width: `calc(100% - ${drawerWidth}px)`,
            width: `calc(100%)`,
            marginLeft: drawerWidth,
          },
      },
    appBarSinloguear: {        
        width: `100% `,
        //marginLeft: drawerWidth,          
    },
    title2: {
        fontSize: 18,
    },
    subTitle2: {
        fontSize: 12,
        fontWeight:600
    },
}))

const Navbar = (propiedades) => {
    let history = useHistory();
    const misclases=useStyles();
    const source = axios.CancelToken.source();        
    const usuID = localStorage.getItem('UsuId');
    const Dispvo =localStorage.getItem('Dispvo') ;    
    const auxDispV = Dispvo && Dispvo.length >= 12 ? Dispvo:'w8rf51v21dsd2cs'    
    const {guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);
    const [avanUser,setAvanUser]=  useState([])
    const[usrTipo,setUsrTipo]=  useState(0);
    const[modalSecc,setModalSecc]=  useState(false);
    const cambiarTema=(auxColor)=>{
        propiedades.setColorTema(!auxColor)
        localStorage.setItem('ColorTheme',!auxColor);
        //console.log(!auxColor);       
    }

    useEffect(() => {
        const nvl = localStorage.getItem("Tipo");
        let aux2=nvl!== null && parseInt(nvl) !== 0 ? parseInt(nvl):0
        setUsrTipo(aux2)
        const llenaAvUsuario=()=>{
            let data = qs.stringify({
              usuario:usuID,                                                      
              idDispositivo: auxDispV,
            });
            function respuesta(auxiliar){                
              if (auxiliar.respuesta === 1) 
              {   //console.log(auxiliar);
                setAvanUser(auxiliar)             
              }                               
            }
            let url = "coordinador-avance";
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source.token);
        }      
        if (propiedades.usuario.length !== 0  ) 
        {
            llenaAvUsuario()
        }
      return () => {
        source.cancel();
      }
    }, [propiedades.usuario])
    
    
    const CerrarSesion =e =>{
        e.preventDefault();        
        localStorage.clear();
        guardarGenerales([])       	
        guardarUsuario([])
        history.replace("/")			
        propiedades.setColorTema(false)
        setAvanUser([])
    }

 
    let auxTitulo=''
    //auxTitulo='Pruebas -'

    const Encabezado=({espacio,estiloCampo,descripcion,campo})=>{
        return(
        <Box {...espacio} >
            <Typography className={misclases.title2}  variant="h5" color="secondary" 
                component="h2" align="center"  style={estiloCampo}
            >                                        
                {campo}
            </Typography>                                            
            <Typography  className={misclases.subTitle2} color="textSecondary"  align="center" >
                {descripcion }
            </Typography>
        </Box>
        )
    }

    const modSecc=modalSecc?<BusqSeccion modalAbierto={modalSecc} setModalAbierto={setModalSecc}/>:null
    return (

    <AppBar className={ propiedades.usuario.length !== 0 ? misclases.appBar
        : misclases.appBarSinloguear } position="fixed" color="primary"
    >
        {modSecc}
        <Toolbar>
           
        {propiedades.usuario.length !== 0  ?
            <IconButton color="inherit" onClick={()=>propiedades.funcionAbrir()} 
                className={misclases.menuButton} aria-label="menu"  size='small'
            >
            <MenuIcon/>
        </IconButton>
        :null
        }
        
        <Box  flexGrow={1} >
            <Box display={"flex"} row justifyContent={"center"}>
                <Typography variant="h6" className={misclases.mititulo}>
                    { generales.length !== 0  && generales?.Titulo !== undefined ?  
                        auxTitulo+generales.Titulo: auxTitulo }
                </Typography>
            {usrTipo !== 7 && avanUser.length!==0 ? 
                <Card style={{marginLeft:1.5+'rem', paddingLeft:1+'rem', 
                    paddingRight:1+'rem',flexDirection:"row"}} >
                    <Box display={"flex"} row>
                    <Encabezado espacio={{ ml:1,mr:1}} estiloCampo={ {fontWeight: '900'}} 
                        descripcion={"Días Faltantes "} campo={`${avanUser.resto}`} />
                    <Encabezado espacio={{ ml:1,mr:1}} estiloCampo={ {fontWeight: '900'}} 
                        descripcion={"Avance Individual"} 
                        campo={ <span  >                        
                            <NumberFormat value={avanUser.avanceUser} 
                                displayType={'text'}  thousandSeparator={true}
                            />{' / '}
                            <NumberFormat value={avanUser.metaUser} 
                                displayType={'text'}  thousandSeparator={true}
                            />
                        </span> } />
                    <Encabezado espacio={{ml:.5 }} estiloCampo={ {fontWeight: '900'}} 
                        descripcion={"Avance General"} 
                        campo={
                            <span  >                        
                            <NumberFormat value={avanUser.avance} 
                                displayType={'text'}  thousandSeparator={true}
                            />{' / '}
                            <NumberFormat value={avanUser.meta} 
                                displayType={'text'}  thousandSeparator={true}
                            />
                        </span>} />      
                    {avanUser?.avanceMun ? 
                        <Encabezado espacio={{ ml:1,mr:1}} estiloCampo={ {fontWeight: '900'}} 
                            descripcion={"Municipio"} campo={`${avanUser.avanceMun} / ${avanUser.metaMun}`} />     
                    :null}
                    {avanUser?.avanceSecc ?
                        <Encabezado espacio={{ml:.5 }} estiloCampo={ {fontWeight: '900'}} 
                        descripcion={"Sección"} campo={
                        <span  >                        
                        <NumberFormat value={avanUser.avanceSecc} 
                            displayType={'text'}  thousandSeparator={true}
                        />{' / '}
                            <NumberFormat value={avanUser.metaSecc} 
                                displayType={'text'}  thousandSeparator={true}
                        />
                        </span>} />                                                        
                    :null}
                    </Box>
                </Card>
            :null }
        </Box>
       
    </Box>
        <Tooltip title="Buscar Sección">
        
        <IconButton color="inherit" onClick={()=>setModalSecc(true)} 
            className={misclases.menuButton} aria-label="menu"  size='small'>
                <SearchIcon/>
        </IconButton> 
        </Tooltip>    
        <MenuAndroid/>
        {propiedades.usuario.length !== 0  ?
            <Box>
                <Tooltip title={!propiedades.colorTema?`Cambiar a Modo Oscuro`:`Cambiar a Modo Claro`}>
                <IconButton color="inherit" onClick={()=>cambiarTema(propiedades.colorTema)} 
                    className={misclases.menuButton} aria-label="menu"  size='small'
                >
                    {propiedades.colorTema ? <Brightness3Icon/>: <BrightnessLowIcon/> }
                </IconButton>
                </Tooltip>
                <Button variant="text" color="inherit" onClick={CerrarSesion}>
                    Cerrar Sesión
                </Button>
            </Box>
            :null
        }        
        </Toolbar>
    </AppBar>
        
    );
}
 
export default Navbar;