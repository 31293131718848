import React,{useEffect,useState,useContext } from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import { TextField,IconButton,Box,Tooltip,LinearProgress,FormControl,
    //FormLabel,FormControlLabel,RadioGroup,Radio
    Select,MenuItem,InputLabel, Typography,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaUsuario from './TablaUsuarios'
import NvoUsuario from './NuevoUsuario'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken, llamadaApiToken, ErrorGeneral } from '../funciones/ClienteAxios';
import TablaPermisoMun from './TablaPermisoMun';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ModAltaReporte from './ModAltaReporte';
const useStyles = makeStyles({    
    buscador:{
    width:'30em'
    }, 
});

const UsuarioInicio = () => {
    const history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();  
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0;
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const auxDispV =localStorage.getItem('Dispvo') && localStorage.getItem('Dispvo').length >= 12 
        ? localStorage.getItem('Dispvo'):'w8rf51v21dsd2cs'             
    const usu= localStorage.getItem('UsuarioNom');
    const {usuario,guardarUsuario}= useContext(AuthContext);
    const {generales,guardarGenerales}= useContext(GeneralesContext);    
   const [usrRegistro, setUsrRegistro] = useState(null); 
    const[usrBusq,setUsrBusq]= useState({nombre:'',nombreFiltrado:''});
    const[listUsu,setListUsu]= useState([]);
    const[espera,setEspera]= useState(false);
    const{nombre,nombreFiltrado}=usrBusq    
    const[esperaMun,setEsperaMun]= useState(false);
    const[actualizar,setActualizar]=useState(false);
    const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false); 
    const[ TipoUsu, setTipoUsu]= useState(1); 
    const[ mensaje, setTMensaje]= useState(false);     
    const [listTipoUsu, setListTipoUsu] = useState([]); 
    const[auxValida,setAuxValida]= useState([])
    const[listUsuCoord,setListUsuCoord]= useState([])
    const[listUsuCoordFtr,setListUsuCoordFtr]= useState([])
    const[listMuni,setListMuni]= useState([]);
    const[listTitular,setListTitular]= useState([]);
    const[listTitularEsp,setListTitularEsp]= useState([]);
    const[ listMunUsr,setListMunUsr]= useState([]);
    const[ actualMunUsr,setActualMunUsr]= useState(false);
    const[ modalRepo,setModalRepo]= useState(false);

    useEffect(() => {                                
        
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            const auxOpera= localStorage.getItem('Operacion');
            let auxValidaOp=false
            if ( auxOpera.indexOf('0') !== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('1')!== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('2')!== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('3')!== -1) 
            {   auxValidaOp=true
            }            
            //console.log(!auxValidacion);
            if(!auxValidacion )
            {  
                guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                                             
                }                                                      
                guardarGenerales({...generales,Titulo:'Usuarios'})   

                
                if (usuID !== null && usuID !== undefined 
                    && (usuID === 1 || nvl > 2 || nvl === 1)) 
                {
                    if ( nvl > 2 ) 
                    {   llenaListUsuCord(usuID)
                        llenaListTipo()  
                        setAuxValida(false)  
                    } 
                    else 
                    {   llenaListTipo()    
                        setAuxValida(auxValidaOp)     
                        if (nvl === 1) {
                            llamadaListMuni()
                            llamadaListTitular(5)
                            llamadaListTitular(11) 
                        }                                       
                    }  
                }
                else
                {     
                    localStorage.clear();                            
                    history.push("/");  
                }              
            }            
        }        
        veriSesion();	

        return ()=> {
            source.cancel();
        }  
    }, [])

    useEffect(()=>{        
        if (nombre.length>=1 ) 
        {
            llamadaBusqUsu(nombre)    
        }        
        else
        {   if (nvl > 2) 
            {
                llenaListUsuCord(usuID)
            }            
        }
      
    },[actualizar])

    useEffect(()=>{        
        if (usrRegistro && usrRegistro.length!==0  ) 
        {
            
            if ((usrRegistro.Tipo===11 || usrRegistro.Id===1)) 
            {
                llenaListMunUsr(usrRegistro)    
            }else
            {
                //setUsrRegistro(null)
                setListMunUsr([])
            }
        }
        
    },[usrRegistro])

    const llenaListMunUsr = (usrRegistro) => {               
        const data = qs.stringify({
            Usuario:usrRegistro.Id  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario-municipio-list";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   setListMunUsr(aux)
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEsperaMun,history,source);
    } 
    
    const llenaListTipo = () => {               
        const data = qs.stringify({
            usuario:usuID  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario-tipo-list";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   let auxFilt = nvl === 1 || nvl === 7 ? aux.filter(tipo => tipo.Id >= nvl)
                    :aux.filter((tipo)=>(tipo.Id >= nvl || tipo.Id===2) &&tipo.Id!==7  )   
                if (nvl === 11 ) 
                {
                    auxFilt=aux.filter((tipo)=>(tipo.Id === nvl))
                }                                                           
                setTipoUsu(nvl )
                setListTipoUsu(auxFilt)   
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 

    function llamadaListMuni()  {            
        const url= `lugar-municipio-tipo`;                            
        const data = qs.stringify({                
            usuario:usuID,              
            estado:process.env.REACT_APP_EDO,                
            tipo:nvl,                        
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   setListMuni(aux)
            }                                                                      
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function llamadaListTitular(tipo)  {            
        const url= `coordinador-municipal-titular-list-1-1`;                            
        const data = qs.stringify({                
            Usuario:usuID,           
            Tipo:tipo,               
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {   
                if (tipo===5) {
                    setListTitular(aux)
                }
                if (tipo===11) {
                    setListTitularEsp(aux)
                }

            }                                                                      
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const llenaListUsuCord = (auxUsuId) => {   
        setUsrRegistro(null)
        setListMunUsr([])            
        const data = qs.stringify({
            usuario:auxUsuId  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario-list-coordinador";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   setListUsuCoord(aux)
                setListUsuCoordFtr(aux)
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    }    
    
    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        {   setTipoUsu( e.target.value)      
        }
        else
        {   setTipoUsu( "" )           
        }        
    }

    const llamadaBusqUsu = (auxNombre) => {     
        setUsrRegistro(null)
        setListMunUsr([])          
        const data = qs.stringify({
            usuario:usuID,
            cadena:auxNombre,
            tipo: TipoUsu,
            idDispositivo:auxDispV,
        });   
        const url = "usuario-list";
        function respuesta(aux) {
            if (aux[0].Id != -1) 
            {  
              setTMensaje(false)         
              //aux.sort(function (a, b) { return a.Id - b.Id; });                                                    
              setListUsu(aux)
            }
            else
            {   setListUsu([])
                setTMensaje(true)             
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 
    
    const btnCargar =()=>{
        if (nombre.length>=1 ) 
        {   llamadaBusqUsu(nombre)
        }      
    }

    const btnCargar2 =()=>{                
        if (nvl > 2) 
        {   llenaListUsuCord(usuID)
        } 
    }  

    const llamadaxEnter=(event)=>{
        if (event.keyCode === 13 && nombre.length>=1) 
        {   llamadaBusqUsu(nombre)            
        }
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[:$!%({})/*|]');
        //let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z.0-9_-]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) //&& !expMenos.test(e.target.value) 
            &&!expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
        {
            setUsrBusq({
                ...usrBusq, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }         
    };

    function abrirModalNvoUsr(){  
       
        setModalNvoUsrAbierto(true);    
    }

    let colorLetra=process.env.REACT_APP_Fondo_Color
    const checkTipo=  listTipoUsu.length > 0 ?       
        <FormControl  style={{marginLeft:1+'rem', minWidth:14+'rem'}} >
            <InputLabel id="tipo">Tipo</InputLabel>
            <Select  
            label="Tipo" id="TipoUsu" name="TipoUsu"
            value={TipoUsu} onChange={guardarTipoSelect}
            >
            {listTipoUsu.map((secc, index) => {
                if(secc.Id>0) {
                return(
                <MenuItem value={secc.Id} key={secc.Id}>
                    <em>{secc.Nom }</em>
                </MenuItem>
                )}
            } )}     
            </Select>
        </FormControl>        
        :null   
    
    const menuAdmin=()=>{
    return(
    <Box display={"flex"} flexDirection="row">
        <Box mt={2}>        
            <TextField className={classes.buscador} placeholder="Nombre" 
                name="nombre" onKeyUp={(event)=>  llamadaxEnter(event)}
                value={nombre} onChange={onChange} 
            />
            <Tooltip title={`Actualizar lista usuarios`}>
                <IconButton aria-label="actualizar" onClick={() => btnCargar()} 
                    component="span" size="small" 
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>
            {/*  */}
            <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                    component="span" size="small" 
                >
                    <AddCircleOutlineIcon style={{color:colorLetra }} />  
                </IconButton>
            </Tooltip>
        </Box>
        {checkTipo}
       {usuID===1? <IconButton aria-label="actualizar" onClick={() => setModalRepo(true)} 
            component="span" size="small" style={{marginLeft:"2rem"}}
        >
            <ListAltIcon style={{color:colorLetra  }} />
        </IconButton>:null}
    </Box>)
    }

    const menuUsu=()=>{
        return(
        <Box display={"flex"} flexDirection="row">
            <Box mt={2}>        
                <TextField className={classes.buscador} placeholder="Nombre" 
                    name="nombreFiltrado" onKeyUp={(event)=>  llamadaxEnter(event)}
                    value={nombreFiltrado} onChange={onChange}
                />                          
            </Box>
            <Tooltip title={`Actualizar lista usuarios`} >
                <IconButton aria-label="actualizar" onClick={() => btnCargar2()} 
                    component="span" size="small" style={{marginTop:.8+'rem'}}
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>           
        </Box>)
    }    
    
    const menuUsuLista=()=>{
        return(
        <Box display={"flex"} flexDirection="row">
            <Box mt={2}> 
             <TextField className={classes.buscador} placeholder="Nombre" 
                name="nombre" onKeyUp={(event)=>  llamadaxEnter(event)}
                value={nombre} onChange={onChange} 
            />
            <Tooltip title={`Actualizar lista usuarios`}>
                <IconButton aria-label="actualizar" onClick={() => btnCargar()} 
                    component="span" size="small" 
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>
            </Box>
            {checkTipo}        
        </Box>)
    } 

    const tablaSinFiltro=(auxLista)=>{
        return(
        <TablaUsuario auxNombre={nombre} listUsu={auxLista} 
            auxActualizar={actualizar} setActualizar={setActualizar}
            listTipoUsu={listTipoUsu}  TipoUsu={TipoUsu}
            recargar={recargar}
        />
        )
    }
    
    function filtro(){        
        let auxCadena=nombreFiltrado.trim();         
        let info=listUsuCoord.filter((dat)=>dat.Nombre.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        return tablaSinFiltro(info);
    }

    const recargar=()=>{
        setActualizar(!actualizar)
    }

    const tablaCoordUsu= nombreFiltrado.length >0 ? filtro() : tablaSinFiltro(listUsuCoord)
    const modNvoUsr = modalNvoUsrAbierto ? 
                        ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                            setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}
                            listTitular={listTitular}
                            listTipoUsu={listTipoUsu} setListTipoUsu={setListTipoUsu}
                            recargar={recargar} listMuni={listMuni} listTitularEsp={listTitularEsp}
                        />
                        ): null; 
    const barraCarga = espera ? <Box pt={3}> <LinearProgress/>  </Box>  :null   
    
    //valida que solo el usuario de tipo admin cree usuarios
    const menu = auxValida ? menuAdmin() : menuUsuLista()

    const modRepo= modalRepo?<ModAltaReporte modalAbierto={modalRepo} setModalAbierto={setModalRepo} /> :null
    return (
    <Box mt={1}>
        {modRepo}
        {menu}
        <br/> 
        {mensaje?<Typography>Sin resultados</Typography>:null}
        {/* listUsuCoord.length>0 && !espera ? 
            tablaCoordUsu
        :null */ }        
        {barraCarga }
        {modNvoUsr}
        <div style={{display:"flex", flexDirection:"row"}}>
        { listUsu.length>0 && !espera ? 
            <TablaUsuario auxNombre={nombre} listUsu={listUsu} 
                auxActualizar={actualizar} setActualizar={setActualizar}
                listTipoUsu={listTipoUsu}  recargar={recargar}
                listMuni={listMuni}  listTitular={listTitular}
                usrRegistro={usrRegistro} setUsrRegistro={setUsrRegistro}
                listTitularEsp={listTitularEsp}
            />
        :null }
        {!esperaMun && usrRegistro && (nvl === 1 ||usuID===usrRegistro.Id) && listMunUsr.length!==0    ?
            <TablaPermisoMun usrRegistro={usrRegistro} datos={listMunUsr} 
                llamaApi={()=>setActualMunUsr(!actualMunUsr)} />
        :esperaMun? <Box mt={4} width={"20rem"}><LinearProgress/></Box>:null
        }
        </div>
    </Box>
    )
}

export default UsuarioInicio
