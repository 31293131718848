import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { TextField,FormControl,//Box,Tooltip,
        FormControlLabel,RadioGroup,Radio,//FormLabel,LinearProgress,   
        InputAdornment,IconButton,Input,     
        InputLabel,Select,MenuItem} from '@material-ui/core'  
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import ModalActualiza from '../generales/ModalCerrarActualiza';
import moment from 'moment'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {llamaApiCargaToken,   ErrorGeneral } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const EditarUsuario = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,listTipoUsu,usrSelecc,subtitulo,recargar, listMuni, listTitular,listTitularEsp }) => {
    let history = useHistory();
    const classes = useStyles();
    const source = axios.CancelToken.source();     
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0; 
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const[nvoRegis,setNvoRegis]= useState({Nombre:'',                                            
                                            Email:'' ,Pass:'',Tel:'',TipoUsu:2,usuNick:'',idUsu:'',stdUsu:'', muni:"",
                                            Titular:"1",Superior:""
                                            });                                                
    const [auxCorreo,setCorreo]= useState('');
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] =  useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const[visiblePwd,setVisiblePwd]= useState(false)    
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const{  Nombre,Email,Pass,Tel,TipoUsu,usuNick,idUsu,stdUsu, muni,Titular,Superior}=nvoRegis
    const [listAuxTitu, setListAuxTitu] = useState([]);
    useEffect(() => {      
        if (TipoUsu===5) 
        {
            setListAuxTitu( listTitular)
        }
        if (TipoUsu===11) {
            setListAuxTitu( listTitularEsp)
        }   
    }, [TipoUsu])
    
    
    useEffect(()=>{
        console.log(usrSelecc);
        let filtro=[]
        if (usrSelecc.Superior!==0 && usrSelecc.Tipo ===5) {
            const aux= listTitular.filter((titu)=>titu.Id===usrSelecc.Superior)
            console.log(aux);
            filtro=aux.length!==0?aux[0]:[]
        }        
        if (usrSelecc.Superior!==0 && usrSelecc.Tipo ===11) {
            const aux= listTitularEsp.filter((titu)=>titu.Id===usrSelecc.Superior)
            console.log(aux);
            filtro=aux.length!==0?aux[0]:[]
        }        
        setNvoRegis({
            ...nvoRegis,
            Nombre: usrSelecc.Nombre,Email:usrSelecc.Email ,Pass:usrSelecc.Pass ,
            Tel:usrSelecc.Tel ,TipoUsu:usrSelecc.Tipo ,usuNick:usrSelecc.Usuario,
            idUsu:usrSelecc.Id ,stdUsu:usrSelecc.Status, muni: usrSelecc.MunId,
            Titular: `${usrSelecc.Titular}`, Superior:filtro
        })
        setCorreo(usrSelecc.Nombre)
    },[])
               
    
    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9.$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis,
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		
	};      

  
    const onChangeSinValidar2 = e =>
	{   
        setCorreo( e.target.value)            
        		
	};   
 
    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		    

	}; 
    
    async function llamadaNvoRegis( )  {       
        setEspera(true);
        const url=`usuario-editar-multiple-1-1`;                                                                       
        const data = qs.stringify({     
            Llave:idUsu,                                                              
            Tipo:TipoUsu, 
            Estatus:stdUsu,
            Nombre:Nombre,
            Usuario:usuNick,
            Pass:Pass,
            Email:Email.length===0? "": Email,
            Telefono:Tel,             
            Municipio:muni!==0 ? muni:muni,
            Titular:TipoUsu!==5 && TipoUsu!==11 ?1:Titular,
            Superior:TipoUsu!==5 && TipoUsu!==11 || Titular==="1" ?0:Superior.Id,
            idDispositivo:'451224452121'
        }); 
        
        function respuesta (aux) {              
          if (aux.length!==0) 
          {                            
            recargar()       
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el Usuario."
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            serCambioEfectivo(true)
            toast.success(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `edit-usuario${1}`
            })
          }                                            
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);             
    };

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
         //   console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
 
    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }

    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
  
    function guardarDatos(e)
    {   e.preventDefault()        
 
        if (!verificaNulos(TipoUsu,'Tipo') 
            && ( ( ( TipoUsu === 5 || TipoUsu === 11) && !verificaNulos(muni,'Municipio')  ) || (TipoUsu !==5 && TipoUsu !== 11))
            &&( ( ( TipoUsu === 5 || TipoUsu === 11 )&& (Titular==="1" || ( Titular==="0" && !verificaNulos(Superior,'Superior') ) ) )
            || ( TipoUsu !==5 && TipoUsu !== 11 ) )    
            && validaVariable(Nombre,'Nombre',7) &&validaVariable(usuNick,'Usuario',4)
            && valContra()  && validaVariable(Tel,'Telefono',10) 
            )
        { 
            setMensaje('');                
            setAviso(false);         
            console.log(nvoRegis);        
             llamadaNvoRegis()                        
        }                                  
    }   
 
    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoRegis({
                ...nvoRegis,
                TipoUsu :e.target.value ,
                Superior : "" 
            })      
        }else
        { setNvoRegis({
            ...nvoRegis,
            TipoUsu : ""  ,
            Superior : "" 
        })           
        }        
    }
    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
      };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();
        //setVisiblePwd(!visiblePwd)
    };

    const guardarMun=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoRegis({
                ...nvoRegis,
                muni : e.target.value 
            })      
        }else
        {   setNvoRegis({
                ...nvoRegis,
                muni : ""  
            })           
        }        
    }

    const guardarSupr=(e)=>{    
        
        if (e!== null && e!== undefined) 
        {                            
            setNvoRegis({
                ...nvoRegis,
                Superior : e  ,                 
            })      
            setMensaje('');                
            setAviso(false); 
        }else
        {   setNvoRegis({
                ...nvoRegis,
                Superior : ""   
            })           
        
        }        
    }

    const onChangeRad=(e)=>{
        setNvoRegis({...nvoRegis,[e.target.name] : e.target.value,   Superior : ""  })            
    }

    const DatosGenerales=(
        <div>
            <form autoComplete='off' > 
            {listTipoUsu.length!==0 &&nvl!==undefined && nvl === 1 ?                
               <FormControl  style={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} >
                   <InputLabel id="tipo">Tipo</InputLabel>
                   <Select  
                     label="Tipo" id="TipoUsu" name="TipoUsu"
                     value={TipoUsu} onChange={guardarTipoSelect}
                   >
                    {listTipoUsu.map((secc, index) => {
                        if(secc.Id>0) {
                        return(
                        <MenuItem value={secc.Id} key={secc.Id}>
                            <em>{secc.Nom }</em>
                        </MenuItem>
                        )}
                    } )}
                    

                   </Select>
                </FormControl>
            :   null
            }
            {nvl===1 && (TipoUsu===5 || TipoUsu===11) && listMuni && listMuni.length !== 0   ? 
                <div>
                <FormControl  style={{  minWidth:20+'rem' }} >
                    <InputLabel id="inpt-mun">Municipio</InputLabel>
                    <Select  
                    label="Municipio" id="sel-muni" name="muni"
                    value={muni} onChange={guardarMun}
                    >
                    {listMuni.map((mun  ) => {
                        if(mun.Id>0) {
                        return(
                        <MenuItem value={mun.Id} key={mun.Id}>
                            <em>{mun.Nom }</em>
                        </MenuItem>
                        )}
                    } )}
                    

                    </Select>
                </FormControl>
                </div>
            :null}
            {nvl===1 && (TipoUsu===5 || TipoUsu===11)  ? 
                <div>        
                <FormControl component="fieldset" style={{marginLeft:.5+'rem',marginTop:".5rem" }}>          
                   {/*  <FormLabel  >Tipo</FormLabel>      */}   
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="Titular" value={Titular} onChange={onChangeRad}
                    >   
                        <FormControlLabel value={"1"}  label={"Titular"}     
                            control={<Radio color="primary" />} />                                 
                        <FormControlLabel value={"0"}  label={"Encargado"}             
                            control={<Radio color="primary" />} />                                                                                  
                    </RadioGroup>                     
                </FormControl>  
                </div>
            :null}
            {nvl===1 && (TipoUsu===5 || TipoUsu===11) && listTitular && listAuxTitu.length !== 0  && Titular==="0" ? 
                <div>            
                <Autocomplete
                    id="sel-Titular" size='small' style={{ width: 20.7+'rem',marginTop:".5rem" }}
                    onChange={(e,option)=>guardarSupr(option)}  value={Superior}      
                    getOptionLabel={(option) => option.UsrNom} name="Superior"             
                    noOptionsText={"Sin coincidencias"} options={listAuxTitu}
                    renderInput={(params) => (
                        <TextField {...params} size='small' 
                        label="Superior" variant="outlined" />
                    )} 
                />
                </div>
            :null}
            <br/>
 
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:23+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" // defaultValue="Nombre"                
                autoComplete='off'
            />
            <br/>
            <TextField  id="usu-input1" onChange={onChange} style={{width:11.5+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                autoComplete='off' disabled={usuID!==1?true:false}
            />      
  
            <FormControl  size="small" style={{width:11.5+'rem'}} >
                <InputLabel htmlFor="pwd-usu1-txt" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-p-usu1"
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='do-not-autofill' name="Pass"
                    onChange={onChange } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>  
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChange} style={{paddingBottom:1+'rem',width:15.5+'rem'}}
                label="Email"  value={Email} name='Email' size="small"//defaultValue="Usuario"
                autoComplete='off'
            /> 
        </form>
        </div>
 
    )       
    const cerrarBorrar = () => {    
        //setOpen(false);        
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {
            setActualizar(!actualizar)
        }
    };

    return (
        <>           
         <ModalActualiza
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}      
            subtitulo={subtitulo}  cerrarBorrar={cerrarBorrar}
        >
            {DatosGenerales}
        </ModalActualiza> 
            
        </>
    )
}

export default EditarUsuario
