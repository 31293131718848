import React,{useState, useEffect} from 'react';
import TablaGeneral from './TablaGeneral'
import {useHistory} from "react-router-dom";
import {Box,IconButton,Tooltip, Breadcrumbs,Typography,
  Card,TextField, LinearProgress} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardPerfil  from './CardPerfil';
//import ContenedorMapa from './ContenedorMapa';
import MapDelegacion from '../MapaEdo';
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";

const arrBase={
  "Id": 0, "MunId": 0, "MunNom": "TODOS",
  "MunNum": 0, "UsrId": 0, "UsrNom": "",
  "UsrTel": "0","UsrUsr":""
}
const GeneralInicio = () => {
  let history = useHistory();
  const idusu = localStorage.getItem("UsuId") ? parseInt(localStorage.getItem("UsuId")):0;
  const usuTitu = localStorage.getItem("usuTitu")?parseInt(localStorage.getItem("usuTitu")) :0
  const source = axios.CancelToken.source();
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
  const[totalPerfil,setTotalPerfil]= useState([]);
  const[actualizar,setActualizar]=useState(true);
  const [arregloColor,setArregloColor] = useState([])  
  const [infoNvlCoord,setInfoNvlCoord] = useState([])   
  const [listMun,setListMun]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)
  const [listEncargado,setListEncargado]=useState([])
  const [cargaList,setCargaList]=useState([])
  const [encargado,setEncargado]=useState([])
  const[nombre, setNombre]=useState("")
  useEffect(() => {
    
  
    if(usuTitu===1){
      llenalistEncarga()
    }
    return () => {
      
    }
  }, [])
  
  const llenalistEncarga = () => {
    const data = qs.stringify({        
        Usuario: idusu,
        idDispositivo: "987gyubjhkkjh",
    });
    const url = "coordinador-municipal-encargado-list";

    function respuesta(aux) {
      if (aux[0].Id!==-1 ) 
      {
        const nvoList=[arrBase, ...aux ]
        setListEncargado(nvoList)
      } 
      else 
      {  
        setListEncargado([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  }; 
    const generarLista = e =>{   
      e.preventDefault()    
      setTotalPerfil([])
      setArregloColor([])
      setActualizar(!actualizar)        
    }
    const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
      //console.log(auxNvl);
      return(
      <span   >
        <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
          style={{fontSize:.8+'rem',fontWeight:600}}
        >
          {auxUser}
        </Typography>
        <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
          style={{fontSize:.7+'rem',textAlign:"center"}}
        >
          {auxNvl}
        </Typography>
      </span>
      )
    }
    
  const nvlInfo=(lista)=>{
  //console.log(lista);
    return(
    <Box mt={1} mb={2}>
      <Breadcrumbs separator={<NavigateNextIcon  />} aria-label="breadcrumb"  >
        {lista.CZId>0 ? letraTipo(lista.CZNom,lista.CZId,lista.CZUser) : null}
        {lista.CRId>0 ? letraTipo(lista.CRNom,lista.CRId,lista.CRUser) : null}
        {lista.CMId>0 ? letraTipo(lista.CMNom,lista.CMId,lista.CMUser) : null}    
        {lista.CSId>0 ? letraTipo(lista.CSNom,lista.CSId,lista.CSUser) : null} 
        {lista.PrId>0 ? letraTipo(lista.PrNom,lista.PrId,lista.PrUser) : null}            
      </Breadcrumbs>
    </Box>
    )
  }
  
  const generaCard=(seleccionado)=>{
    let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
    //console.log(auxMun);
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
    </Card>
    )
  }

  const guardarMuni=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    { setEncargado(e)            
    }
    else
    { setEncargado([])     
    }
 
  }

  const onChange = e => {
    setNombre( e.target.value)
  }
  const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      <Box display={"flex"}>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
        <Box pl={1} pt={1}>
          { usuTitu ===1 && listEncargado.length!==0 ?
            <Autocomplete
              id="encargados-autocomplete-01" size='small'
              onChange={(e,option)=>guardarMuni(option)}
              options={listEncargado}
              getOptionLabel={(option) => `${option.MunNom} - ${option.UsrNom} ${option.UsrUsr!==""?"("+option.UsrUsr+")":""}`}
              style={{ width: 40+'rem' }}
              noOptionsText={"Sin coincidencias"}
              renderInput={(params) => (
                  <TextField {...params} 
                  label="Encargados" variant="outlined" />
              )}
            />
          :<LinearProgress/>}
        </Box>  
        {idusu === 1 ?
          <Box pl={1} pt={1}>
            <TextField id="nombre-txt" name="nombre" label="Nombre" fullWidth size="small"
              value={nombre} onChange={onChange} variant="outlined"/>  
          </Box>
        :null }
      </Box>
    </Box>   
    {/* infoNvlCoord.length > 0 ? nvlInfo(infoNvlCoord[0]) :null */}
    <Box>
      {totalPerfil.length !== 0 ? 
      <CardPerfil valor={totalPerfil} />
      :null}
    </Box>
    <Box display={"flex"} row  width={"100%"} >
      <Box width={"71%"} style={{minWidth:63+'rem'}} >
      <TablaGeneral fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
        setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
        setInfoNvlCoord={setInfoNvlCoord } setListMun={setListMun} listMun={listMun} 
        encargado={encargado} nombre={nombre}
      />
      </Box>
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} >
      {/* <span  >
        {cardMuni}
        <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
      </span> */}
        <span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor}/>
        </span>
      </Box>
      :null
      }
    </Box>
  </div>
  );
};

export default GeneralInicio;