import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/Modal";
import qs from "qs";
import {ErrorGeneral, llamadaApiCarga} from "../funciones/ClienteAxios";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
import CardEditarApoyo from "./CardEditarApoyo";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1), padding: 0
    },
}));

const EditarApoyo = ({
                         apoyo,
                         titulo,
                         modalAbierto,
                         setModalAbierto,
                         history,
                         UserId,
                         DeviceId,
                         listTipo,
                         perfil,
                         fuente,
                         actualizar
                     }) => {

    const classes = useStyles();
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [aviso, setAviso] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);
    const [apoyoList, setApoyoList] = useState([]);
    const [bandera, setBandera] = useState(false);
    const [listMod, setListMod] = useState([]);

    useEffect(() => {

        setApoyoList(apoyo)
    }, [])

    const muestraLista = () => {

        setTimeout(() => {

            let nvo = []
            listMod.forEach(element => {
                nvo.push({
                    "tipo": element.TipoId, "estatus": element.StatusId,
                    "cantidad": element.Cant, "obser": element.Observ
                })
            });

            editarApoyo(nvo)

        }, 200);
    }

    const editarApoyo = (arreglo) => {

        let data = qs.stringify({
            Perfil: perfil.Id,
            Usuario: UserId,
            Tipos: JSON.stringify(arreglo),
            idDispositivo: DeviceId,
        });
        let auxiliar = true
        let url = ""
        if (fuente === 1) {
            url = "apoyo-perfil-det-edit"
            auxiliar = false
        }
        if (auxiliar && fuente === 2) {
            url = "apoyo-publico-perfil-det-edit"
        }

        function response(response) {
            if (response) {
                let mensaje = "Promovido editado correctamente.";
                actualizar()
                toast.success(mensaje, {
                    pauseOnFocusLoss: false, toastId: `nvo-perfil${7}`,
                });

                setModalAbierto(false)
            } else {
                let mensaje = response.mensaje;
                toast.warning(mensaje, {
                    pauseOnFocusLoss: false, toastId: `nvo-perfil${7}`,
                });
            }
            setCargando(false);
        }

        llamadaApiCarga(data, url, response, ErrorGeneral, setCargando, history);
    }

    const guardarDatos = () => {
        setBandera(true)
        muestraLista()
    };

    return (<Modal
        hideBackdrop
        disableEscapeKeyDown
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={guardarDatos}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        setAviso={setAviso}
        tamanio={""}
        btnDeshabilitado={botonDeshabilitado}
        cargando={cargando}
        subtitulo={""}
    >
        <form className={classes.root} autoComplete="off">
            {listTipo.length !== 0 && apoyoList.length !== 0 ?
                listTipo.map((element, index) => {
                    return <CardEditarApoyo index={index} element={element}
                                            setBotonDeshabilitado={setBotonDeshabilitado}
                                            bandera={bandera} setListMod={setListMod} listMod={listMod}
                                            listOrig={apoyoList}/>
                })
                : null
            }
        </form>
    </Modal>);
};
export default EditarApoyo;
