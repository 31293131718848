import React,{useState,useEffect,useContext} from 'react';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { useHistory } from "react-router-dom";
import { authUser } from '../funciones/AuthUser'
import axios from 'axios';
import TablaEncuesta from './TablaEncuesta';
import ModalNvoEdit from './ModalNvo';
import { Button } from '@material-ui/core';
const Encuesta = () => {
  const history = useHistory();
  const source = axios.CancelToken.source();
  const nvl= localStorage.getItem('Tipo');          
  const usu= localStorage.getItem('UsuarioNom');   
  const {usuario,guardarUsuario}= useContext(AuthContext);
  const {generales,guardarGenerales}= useContext(GeneralesContext);    
  const [modalNvo,setModalNvo]= useState(false);    
  useEffect(()=>{             
    const autenticado = authUser();
    if(!autenticado)
    {   localStorage.clear();
        history.push("/");
        guardarUsuario([])
    }
    else
    { if (usuario.length===0) 
      {   guardarUsuario({Nombre:usu,nivel:nvl});                                                           
      }                       
       
      guardarGenerales({...generales,Titulo:'Encuestas'})              
    }
    
    return ()=> {
      source.cancel();
    }      
  //la siguiente linea puede generar errores o no en el useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const modNvo = modalNvo ? <ModalNvoEdit modalAbierto={modalNvo} setModalAbierto={setModalNvo} 
                              titulo={"Nueva Encuesta"} getDatos={()=>{}} /> : null
  return (
  <div>
    {modNvo}
    <Button onClick={()=>setModalNvo(true)}>
      Nuevo
    </Button>
    <TablaEncuesta/>
  </div>
  )
}

export default Encuesta