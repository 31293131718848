import React from 'react'
import {FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Select, TextField, Typography} from "@material-ui/core";
const CampoText = ({texto,tipo,valor,setValor}) => {
  
  const onChange = e =>
	{   
    const expNopermitida = new RegExp('[%{}*|`]');  
    const expMenos = new RegExp("'");
    const expMas = new RegExp('"');             
    const expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
      && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
      || e.target.value===' '|| e.target.value==='') 
    {
      setValor({
        ...valor, 
        [e.target.name] : e.target.value//.toUpperCase()  
      })            
    }		
	};      
  
  const onChangeRad=(e)=>{
    setValor({...valor,[e.target.name] : e.target.value, })            
  }

  return (
    <div>
      <Typography>{texto}</Typography>
      { tipo === 1 ?
        <TextField  required id="Nombre-input" onChange={onChange} 
          label={texto} value={valor} name='valor'size="small" autoComplete='off'                           
        />
        
        :<FormControl component="fieldset" style={{marginLeft:.5+'rem' }}>          
          <FormLabel  >{texto}</FormLabel>        
          <RadioGroup  row aria-label="position"  defaultValue="top"
            name="valor" value={valor} onChange={onChangeRad}
          >                        
            <FormControlLabel value={"0"}  label={"No"}             
              control={<Radio color="primary" />} />
            <FormControlLabel value={"1"}  label={"Si"}     
              control={<Radio color="primary" />} />                                                                      
          </RadioGroup>                     
      </FormControl>  
      }
    </div>
  )
}
export default CampoText