import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalNvoEdit from './ModalNvo';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ModalFoto from './ModalFoto';
import CreateIcon from '@material-ui/icons/Create';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ModalMapa from './ModalMapa';
import ModalAgregarEvidencia from './ModalAgregarEvidencia';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ModalFotografia from './ModalFotografia';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '80rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        padding:'1rem',
        maxHeight:'100%',
        minHeight: 280,
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '78rem',
      minHeight: window.innerHeight-190,
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '78rem',
        maxHeight: window.innerHeight-240,
        minHeight: 280,
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[              
    {
      id:1, titulo:"Tipo", alinear:'center'
    },     
    {
      id:2, titulo:"Nombre", alinear:'left'
    }, 
    {
      id:3, titulo:"Edad", alinear:'center'
    },
    {
      id:4, titulo:"Teléfono", alinear:'center'
    }, 
    {
      id:5, titulo:"Sección", alinear:'center'
    },  
    {
      id:6, titulo:"Municipio",alinear:'center'
    }, 
    {
      id:11, titulo:"Distrito",alinear:'center'
    },
    {
      id:7, titulo:"Participó", alinear:'center'
    },                 
    {
      id:8, titulo:"Genero", alinear:'center'
    },
    {
      id:9, titulo:"Ine", alinear:'center'
    }, 
    {
      id:10, titulo:"Email", alinear:'center'
    },  
    
    
  ];

  const archivo=[{id:0,part:"fm", dir:"partido_fm.svg"},
    {id:1,part:"individual", dir:"partido_individual.png"},
    {id:2,part:"mc", dir:"partido_mcu.svg"},
    {id:3,part:"morena", dir:"partido_mor.jpg"},
    {id:4,part:"morptna", dir:"partido_morptna.png"},
    {id:5,part:"na", dir:"partido_na.svg"},
    {id:6,part:"nulo", dir:"partido_nulo.png"},
    {id:7,part:"pan", dir:"partido_pan.svg"},
    {id:8,part:"panpriprd", dir:"partido_panpriprd.png"},
    {id:9,part:"pes", dir:"partido_pes.svg"},
    {id:10,part:"prd", dir:"partido_prd.svg"},
    {id:11,part:"pri", dir:"partido_pri.svg"},
    {id:12,part:"pt", dir:"partido_pt.svg"},
    {id:13,part:"ptmorena", dir:"partido_ptmorena.png"},
    {id:14,part:"pvem", dir:"partido_pvem.svg"},
    {id:15,part:"rsp", dir:"partido_rsp.svg"}]

const alturaCont = window.innerHeight<937 ? window.innerHeight-180 : window.innerHeight-200

export default function TablaRegional({muni,listMuni, listAllSecc,listSeccDto, llamaListSeccDto,listPart,getDatos,llenaTotales}){    
    const classes = useStyles();    
    const [promotor, setPromotor] = useState({nombre:""})
    const [seleccion, setSeleccion] = useState(null)
    const [datos,setDatos] = useState([]);     
    const [modalNvo, setModalNvo] = useState(false);  
    const [modalFoto, setModalFoto] = useState(false);  
    const [actualizar, setActualizar] = useState(false);  
    const [modalMap, setModalMap] = useState(false);  
    const [modalEvidencia, setModalEvidencia] = useState(false);  
    const auxCol=process.env.REACT_APP_Fondo_Color
    const {nombre} = promotor;    
    useEffect(()=>{

        setDatos(muni)

        //la siguiente linea puede generar errores o no en el useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[muni])          

    const onChangeTable =(regis)=>{                       
        setSeleccion(regis)
    }

    function filtro(){        
      let auxCadena=nombre.trim();       
      let info=[];                        
      let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
      info=aux 
      let auxNum=0;
        if (aux!==null||aux!=='') 
        {
        aux.forEach((mun)=>
        { if (mun.nombreReg!=='EDOMEX')
          {   
            auxNum++;
          }                
        })
      }         
      return TablaEstr(info);
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[._:$!%-({})/*|]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) 
            && !expMas.test(e.target.value)) || e.target.value===' '||e.target.value==='') 
        {
            setPromotor({
                ...promotor, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }
    };

    const celda = (alinear, estilo, contenido,index) => {
        return (
        <TableCell align={alinear} key={index} className={classes.tableCell} 
            padding={'none'} style={estilo} >
            {contenido}
        </TableCell>)
    }
    
  const celdaNvo=()=>{
    return(
    <Tooltip title={`Nuevo `}>
      <IconButton aria-label="agregar nuevo" onClick={() => setModalNvo(true)} 
        component="span" size="small" 
      >
        <AddCircleOutlineIcon style={{color:"white" }} />  
      </IconButton>
    </Tooltip> 
    )
  }

  const partidoFun=(regis)=>{
  //  console.log(regis);
    const filtro =archivo.filter((partido)=>partido.part.toUpperCase() === regis.PartNom.toUpperCase())
    let respues=null
    if (filtro[0]&& filtro[0].length!==0) 
    {
      respues=<img src={`./partidos/${filtro[0].dir}`} style={{width:30,marginTop:".1rem" }} />
    }
    else
    { respues=null//<img src={`./partidos/partido_na.svg`} style={{width:30 }} />
    }
    return respues
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
            <CelTitl index={1} />
            <CelTitl index={2} />
            <CelTitl index={3} />
            <CelTitl index={4} />
            <CelTitl index={5} titulo={auxDatos.length} />            
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>                                  
        {auxDatos.map((elemnto,index) => {  
          const auxVal=seleccion?.Id === elemnto.Id ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index} onClick={ () => onChangeTable(elemnto) }  
          >   
            {celda("center", {width:"2rem"}, 
              elemnto.Fotos!==0?<Tooltip title={`Galeria `}>
              <IconButton aria-label="agregar nuevo" onClick={() => setModalFoto(true)} 
                component="span" size="small" 
              >
                <PhotoLibraryIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </IconButton>
            </Tooltip> :null
            ,index+1) }   
              {celda("center", {width:"2rem"}, 
              <Tooltip title={`Agregar Evidencia `}>
              <IconButton aria-label="agregar nuevo" onClick={() => setModalEvidencia(true)} 
                component="span" size="small" 
              >
                <AddPhotoAlternateIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </IconButton>
            </Tooltip> 
            ,index+2) } 
            {celda("center", {width:"2rem"}, 
              elemnto.Lat!==0&&elemnto.Lon!==0?<Tooltip title={`Mapa `}>
              <IconButton aria-label="agregar nuevo" onClick={() => setModalMap(true)} 
                component="span" size="small" 
              >
                <LocationOnIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </IconButton>
            </Tooltip> :null
            ,index+3) }   
             {celda("center", {width:"2rem"}, 
            <Tooltip title={`Editar `}>
              <IconButton aria-label="agregar nuevo" onClick={() => setModalNvo(true)} 
                component="span" size="small" 
              >
                <CreateIcon style={{color:auxVal? "white":"" }} color={auxVal? "inherit":"primary" } />  
              </IconButton>
            </Tooltip> 
            ,index+4) }      
            {celda("center", {width:"2rem"}, elemnto.Id,index+5) }                                 
            {celda("center", {width:"3.5rem"}, elemnto.Tipo==="NO"? "Observ":elemnto.Tipo,index+6) }
            {celda("left", {fontSize:".7rem"},`${elemnto.Nom } ${elemnto.Pat }  ${elemnto.Mat }`,index+7) }            
            {celda("center", {width:"3rem"}, elemnto.Edad,index+8) }  
            {celda("center", {width:"3rem"}, elemnto.Tel,index+9) }    
            {celda("center", {width:"4rem"}, elemnto.SecNum,index+10) }
            {celda("center", {fontSize:".7rem"}, elemnto.MunNom,index+11) }    
            {celda("center", {fontSize:".7rem",width:"3.5rem"}, elemnto.DtoNom,index+12) }   
            {celda("center", {}, 
              elemnto.PartId!==0? partidoFun(elemnto):""
            ,index+12) }         
            {celda("center", {}, elemnto.Genero,index+13) }      
            {celda("center", {fontSize:".7rem"}, elemnto.Ine,index+14) }      
            {celda("left", {fontSize:".7rem"}, elemnto.Email,index+15) }                                                         
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
    const datGeneral = (<Box display="flex" flexDirection="column"  >
                            {/* seleccionado.length !== 0 ?  
                            <Box display="flex" flexDirection="row" >
                                <DatosCard valor={seleccionado}/>                                                    
                            </Box>
                            :null */}
                            <Box display="flex" flexDirection="row">                                               
                         
                                { /* arregloColor.length>0?
                                <MapDelegacion regSelect={regSelect} arregloColor={arregloColor}/>
                                :null */}
                               {/*  <Box style={{marginLeft:2+'rem'}} pt={1} display="flex" 
                                    flexDirection={"column"}
                                >                                    
                                    <Box >
                                        <Graficas valor={seleccionado}  />                             
                                    </Box> 
                                    <br/>                              
                                </Box>     */}                            
                            </Box>                            
                        </Box>) 

  const modMap = modalMap ? <ModalMapa seleccionado={seleccion} titulo={"Mapa Registro"} subtitulo={""}
                                    setModalAbierto={setModalMap } modalAbierto={modalMap}/> : null;   
    const contenido = nombre.length > 0 ? (filtro()):(TablaEstr(datos));    
    const llamada= datos.length !== 0 ?(contenido):(<Box style={{paddingTop:1+'rem'}}><Typography variant="h4" component="h3">No contiene datos</Typography></Box>)
    const modNvo = modalNvo ? 
                        <ModalNvoEdit modalAbierto={modalNvo} setModalAbierto={setModalNvo} getDatos={getDatos}
                            titulo="Editar Registro" listMun={listMuni} listSecc={listAllSecc}   
                            listPart={listPart} auxReg={seleccion}  listSeccDto={listSeccDto} llamaListSeccDto={llamaListSeccDto}         
                        />:null
  const modFoto =modalFoto ?<ModalFoto modalAbierto={modalFoto} setModalAbierto={setModalFoto} 
                              titulo={`Fotografias ${seleccion.Nom } ${seleccion.Pat }  ${seleccion.Mat }`} seleccionado={seleccion}                                
                            />:null                                                                                        
  /* const modEvidenc = modalEvidencia ? <ModalAgregarEvidencia abrirModal={modalEvidencia} setAbrirModal={setModalEvidencia} registro={seleccion}  
                                    setUpdate={getDatos} />                           
                                :null  */                                                            
  
  const modEvidenc = modalEvidencia ? <ModalFotografia modalAbierto={modalEvidencia} setModalAbierto={setModalEvidencia} seleccionado={seleccion}  
                                        titulo={`Subir Evidencia ${seleccion.Nom } ${seleccion.Pat }  ${seleccion.Mat }`} setActualizar={setActualizar} getDatos={getDatos}
                                        actualizar={actualizar} 
                                      />                           
                                :null                                
                                
  return (
  <Box display={'flex'} flexDirection="row" style={{height: alturaCont}}>
    <Card className={classes.estiloCard} style={{height:window.innerHeight-210}}>                        
      {/*  <TextField className={classes.formControl}
        name="nombre" placeholder="Zona"
        value={nombre} onChange={onChange}
      />	   */}          
      {llamada}   
    </Card>
    {modEvidenc}
    {modMap}
    {modNvo}
    {modFoto}
    {datGeneral}
  </Box>                    
  );
}